import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    TextField,
    Grid,
    Container,
    Stack,
    FormControl,
    InputLabel,
    Select,
    styled,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    Typography,
    Autocomplete,
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { GetNewMobileApplicationList, Application_set, PostReject, PostNewMobile } from "~slices/Application";

import { initialize, Upload, } from "~slices/UploadCSVMbile";

import { DataGrid, jaJP } from "@mui/x-data-grid";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});


const InsertNewMobile = () => {
    const dispatch = useDispatch();

    const [applicant_email, setApplicantEmail] = React.useState('');
    const ApplicationState = useSelector((state) => state.Application);


    React.useEffect(() => {
        const search_item = {
            ...ApplicationState.search_item,
            applicant_email: applicant_email,
        };

        dispatch(Application_set(["search_item", search_item]));
        dispatch(GetNewMobileApplicationList(search_item));
    }, []);

    const [file_name, setFileName] = React.useState('');


    const InputCSV = (e) => {
        const target_files = e.target.files;
        const files = Array.from(target_files);
        const file = files[0];
        if (!file) return;

        setFileName(file.name);
    };

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        const files = Array.from(elem.csv.files);
        const file = files[0];
        const data = new FormData();

        data.append("file", file);

        dispatch(Upload(data));

    };


    // CSVダウンロード用の関数
    const downloadCSV = () => {
        const headers = [
            '電話番号',
            'メールアドレス',
            '使用者名',
            '会社名',
            '部署名',
            '経費負担コード',
            'キャリア',
            '機種',
            '利用用途',
            '反映月'
        ];

        const sampleData = [
            ['09012345678', 'sample@example.com', '山田太郎', 'サンプル株式会社', '営業部', '12345', 'docomo', 'iPhone 13', '業務用', '2025-03-01'],
            ['08023456789', 'test@example.com', '鈴木一郎', 'テスト株式会社', '開発部', '67890', 'au', 'Galaxy S21', '個人用', '2025-03-02']
        ];

        // CSVデータ作成
        const csvContent = [
            headers.join(','), // ヘッダー
            ...sampleData.map(row => row.join(',')) // データ
        ].join('\n');

        // CSVファイルとしてダウンロード
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, csvContent], { type: 'text/csv;charset=utf-8;' });
        // const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'sample_data.csv';
        link.click();
    };

    const [open, setOpen] = useState(false); // 詳細表示を制御する状態

    const toggleDetails = () => {
        setOpen(!open); // 詳細表示/非表示を切り替える
    };
    return (
        <React.Fragment>
            <Typography variant="h4">回線情報新規登録</Typography>

            {/* <SearchItemInput /> */}

            <Box sx={{ mt: '1rem', border: 'solid 1px rgb(181, 180, 180)', p: '0.5rem', borderRadius: '0.5rem' }}>

                <Typography variant="h6">CSV一括登録・更新</Typography>
                <br />
                <form onSubmit={(e) => HandleSubmit(e)} id="form1">


                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                        CSVファイルを選択
                        <VisuallyHiddenInput
                            type="file"
                            id="csv"
                            name="file[]"
                            accept=".csv"
                            multiple
                            onChange={(e) => InputCSV(e)}
                            sx={{ m: 1 }}
                        />
                    </Button>





                    <Stack spacing={1} sx={{ m: 1 }}>


                        {file_name ?
                            <Chip
                                label={file_name}
                                color={"success"}

                            // onClick={() => dispatch(UploadCSV_set(["selected", name]))}
                            /> : ''}


                    </Stack>

                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={toggleDetails}
                        sx={{ marginTop: 2 }}
                    >
                        {open ? '閉じる' : 'CSV一括登録の説明を開く'}
                    </Button>


                    {open ?
                        <Box sx={{ padding: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                CSV一括登録・更新の概要
                            </Typography>
                            <Typography variant="body1" paragraph>
                                回線マスタに一括登録または、更新が可能です。<br />
                                同じ電話番号がすでに存在する場合、CSVに記載された情報以外は、その電話番号の現在の情報を取得し、受け継がれて更新します。
                            </Typography>

                            <Typography variant="h6" gutterBottom>
                                CSVアップロードに関する指示
                            </Typography>
                            <Typography variant="body1" paragraph>
                                以下の情報のCSVをアップロードしてください。
                                <br/>順番も下記の通り指定してください。
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        ・電話番号
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        ・メールアドレス
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        ・使用者名
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        ・会社名
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        ・部署名
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        ・経費負担コード
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        ・キャリア
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        ・機種
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        ・利用用途
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        ・反映月 (形式: YYYY-MM-DD)
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography variant="body1" paragraph>
                                ※「電話番号」と「メールアドレス」と「反映月」は必須項目です。
                            </Typography>

                            <Typography variant="body1" paragraph>
                                ※文字コードは「UTF8」を使用してください。
                            </Typography>
                            <Typography variant="body1" paragraph>
                                サンプルは下記からダウンロードできます。
                            </Typography>


                        </Box> : ''}


                    {/* CSVダウンロードボタン */}

                    <Box display="flex" justifyContent="flex-end">

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={downloadCSV}
                            sx={{ marginTop: 2, marginRight: '0' }}
                        >
                            サンプルCSVをダウンロード
                        </Button>
                    </Box>


                    <Stack spacing={1} sx={{ m: 1 }}>
                        <Button
                            form="form1"
                            type="submit"
                            variant="contained"
                            disabled={file_name ? false : true}
                        >一括登録</Button>
                    </Stack>
                </form>
            </Box>
            <ListTable />
        </React.Fragment>
    )
}

const SearchItemInput = () => {
    const dispatch = useDispatch();

    const InformationState = useSelector((state) => state.Information);

    // const [applicant_email, setApplicantEmail] = React.useState(InformationState?.user_info[0]?.Address);
    const [applicant_email, setApplicantEmail] = React.useState('');
    const ApplicationState = useSelector((state) => state.Application);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;

        // const email = elem?.email?.value;

        const search_item = {
            ...ApplicationState.search_item,
            applicant_email: applicant_email,
        };

        dispatch(Application_set(["search_item", search_item]));
        dispatch(GetNewMobileApplicationList(search_item));
    };

    return (
        <React.Fragment>
            <form onSubmit={HandleSubmit}>
                <Box
                    sx={{
                        width: "100%",
                        mt: "1rem",
                        display: "grid",
                    }}
                >
                    <Box sx={{ mb: 1, display: 'none' }}>
                        <TextField
                            sx={{ width: "20rem" }}
                            value={applicant_email}
                            InputLabelProps={{ shrink: true }}
                            type="email"
                            label="申請者メールアドレス"
                            name="applicant_email"
                            onChange={(e) => setApplicantEmail(e.target.value)}
                        />{" "}
                    </Box>

                    <Button variant="contained" color="primary" type="submit">
                        検索
                    </Button>
                </Box>
            </form>
        </React.Fragment>
    );
};

const ListTable = (props) => {
    const ApplicationState = useSelector((state) => state.Application);
    const dispatch = useDispatch();
    const [selected_application, setSelecteApplication] = React.useState(false);
    const [tel_hyphen, setTelHyphen] = React.useState("");
    const HandleClick = (row) => {
        setSelecteApplication(row);
    };

    const popup_columns = [
        { headerName: "申請ID", field: "application_id" },
        { headerName: "申請者メールアドレス", field: "applicant_email" },
        { headerName: "申請者名", field: "applicant" },
        { headerName: "キャリア", field: "career" },
        { headerName: "使用者名", field: "user_name" },
        { headerName: "使用者メールアドレス", field: "user_email" },
        { headerName: "使用者部署名", field: "user_department" },
        { headerName: "使用者会社名", field: "user_company" },
        { headerName: "機種", field: "model" },
        { headerName: "申請理由/特記事項", field: "remarks" },
        { headerName: "用途", field: "usage" },
        { headerName: "使用者種別", field: "user_type" },
        { headerName: "経費負担コード", field: "cost_code" },
        { headerName: "勘定コード", field: "account_code" },
        { headerName: "申請日", field: "created_at" },
        { headerName: "承認者", field: "approver_name" },
        { headerName: "承認者メールアドレス", field: "approver_email" },
    ];

    const columns = [
        { headerName: "申請ID", field: "application_id", width: 130 },
        { headerName: "申請者メールアドレス", field: "applicant_email", width: 250 },
        { headerName: "申請者名", field: "applicant", width: 150 },
        { headerName: "キャリア", field: "career", width: 150 },
        { headerName: "使用者メールアドレス", field: "user_email", width: 250 },
        { headerName: "使用者名", field: "user_name", width: 150 },
        { headerName: "使用者部署名", field: "user_department" },
        { headerName: "使用者会社名", field: "user_company" },
        {
            headerName: "登録",

            field: "insert",
            renderCell: (params) => (
                <Button variant="contained" color="primary" onClick={() => HandleClick(params.row)}>
                    回線登録
                </Button>
            ),
        },
    ];

    const handleNewMobile = (e) => {
        e.preventDefault();

        dispatch(
            PostNewMobile({
                application_id: selected_application.application_id,
                tel_hyphen: tel_hyphen,
                model: selected_application.model,
                user_email: selected_application.user_email,
                user_name: selected_application.user_name,
                division_name: selected_application.user_department,
                career: selected_application.career,
                company_name: selected_application.user_company,
                cost_code: selected_application.cost_code,
                created_at: selected_application.created_at,
            })
        ).then((data) => {
            setSelecteApplication(false);
            setTelHyphen("");
            dispatch(GetNewMobileApplicationList(ApplicationState.search_item));
        });
    };

    return (
        <React.Fragment>
            <React.Fragment>




                <Dialog
                    open={selected_application == false ? false : true}
                    onClose={() => setSelecteApplication(false)}
                >
                    <form onSubmit={handleNewMobile}>
                        <DialogTitle sx={{ m: 0, p: 2, background: "deepskyblue" }}> 回線登録</DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={() => setSelecteApplication(false)}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: "#454545",
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Box
                            sx={{
                                // minWidth: "40rem",
                                width: "100%",
                                mt: "1rem", // minWidth: "100%"
                                display: "grid",
                            }}
                        >
                            <Table>
                                <TableBody>
                                    {popup_columns.map((val, i) => (
                                        <React.Fragment key={i}>
                                            {selected_application[val.field] ? (
                                                <TableRow>
                                                    <TableCell>{val.headerName}</TableCell>
                                                    <TableCell> {selected_application[val.field]}</TableCell>
                                                </TableRow>
                                            ) : (
                                                <></>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box
                            sx={{
                                mt: "1rem",
                                p: 1,
                            }}
                        >
                            {/* <TextField
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label="電話番号"
                                required
                                name="tel_hyphen"
                                // value={tel_hyphen}
                                inputProps={{
                                    maxLength: 4,
                                    pattern: "^[0-9]{3}$",
                                }}
                                // onChange={(e) => setTelHyphen(e.target.value)}
                                multiline
                                fullWidth
                                // helperText="※ハイフンを付けて入力してください"
                            /> */}
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label="電話番号"
                                required
                                onChange={(e) => setTelHyphen(e.target.value)}
                                value={tel_hyphen}
                                inputProps={{
                                    maxLength: 20,
                                    pattern: "^[0-9]{3}-?[0-9]{4}-?[0-9]{4}$",
                                }}
                                size="small"
                                fullWidth
                                name="tel_hyphen"
                                sx={{ mt: "1rem" }}
                            />
                        </Box>
                        <DialogActions>
                            <Button variant="contained" type="submit">
                                登録
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>

            <Box
                sx={{
                    minHeight: 900,
                    width: "100%",
                    mt: "1rem", // minWidth: "100%"
                    display: "grid",
                }}
            >
                <DataGrid
                    rows={ApplicationState.application_list}
                    columns={columns}
                    disableCellSelectionOnClick
                    disableSelectionOnClick
                    pagination
                    // onRowClick={(e) => HandleRowClick(e.row)}
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
        </React.Fragment>
    );
};

export default InsertNewMobile;