import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    TextField,
    Grid,
    Container,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    Typography,
    Autocomplete,
    Button,
    Box,
} from "@mui/material";

import { SerchBillingStatus, BillingStatus_set, DounLoadBillingStatus } from "~slices/BillingStatus";

import { DataGrid, jaJP } from "@mui/x-data-grid";

import SelectUser from "~/components/block/SelectUser";
import { useHistory } from "react-router";

import { addDays, addMonths, format, subMonths } from "date-fns";
const BillingContentStatus = () => {
    const dispatch = useDispatch();

    const BillingStatusState = useSelector((state) => state.BillingStatus);

    const InformationState = useSelector((state) => state.Information);

    const ago_month = (arg) => {
        const day = subMonths(new Date(), 1);
        const format_day = format(day, "yyyy-MM");
        return format_day;
    };



    const [month, setMonth] = React.useState(ago_month);

    const [csv_arg, setCsvArg] = React.useState("");

    const HandleSubmit = (e) => {
        dispatch(SerchBillingStatus());
        // e.preventDefault();
    };

    const SetUserList = (selected_data) => {
        //    const  user_data = new_list.map((data) => {
        //         return {
        //             DisplayName: data.DisplayName,
        //             Address: data.Address,
        //             Department: data.Department,
        //             Company: data.Company,
        //         };
        //     });

        setCsvArg({
            month: month,
            company: selected_data.company,
            department: selected_data.department,
            cost_code: selected_data?.cost_code,
        });

        dispatch(
            SerchBillingStatus({
                month: month,
                company: selected_data.company,
                department: selected_data.department,
                cost_code: selected_data?.cost_code,
            })
        );
    };

    const HandleDounload = () => {
        dispatch(DounLoadBillingStatus(csv_arg));
    };
    const HandleAllDounload = () => {
        dispatch(DounLoadBillingStatus({ month: month, }));
    };
    return (
        <React.Fragment>
            <Typography variant="h4">部門別利用明細表示</Typography>
            部門における通信費請求内容を確認します



            {/* <Box sx={{ m: 1, p: 1, border: "solid 1px gray", background: 'yellow' }}>

                2024年3月以前のデータを確認したい場合は旧システムを確認ください<br />
                <a href="https://www.hi-vox.jp/kurita/login.aspx?ReturnUrl=%2fkurita%2fa%2fdefault.aspx%3fwc%3d1&wc=1">旧システム </a>
            </Box> */}

            <Grid sx={{ m: 1, p: 2, border: "solid gray 1px", borderRadius: "0.5rem" }}>
                <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    type="month"
                    label="請求月"
                    name="month"
                    size="small"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                // {...props}
                />
                <Box sx={{ mt: 1 }}>
                    <Box sx={{ mt: 1, mb: 1 }}>
                        ※会社名＋経費負担コードでも検索ができます
                    </Box>
                    <SelectUser
                        mode="department"
                        onSet={(new_list) => SetUserList(new_list)}
                        inputCostCode
                        buttonLabel="検索"
                    />

                    {csv_arg ?
                        <Button onClick={HandleDounload}>CSVダウンロード</Button> :
                        <>
                            {InformationState?.user_type == "admin" ? (
                                <Button onClick={HandleAllDounload}>全社分CSVダウンロード</Button>)
                                : ''}
                        </>
                    }


                </Box>
                {/* <Button onClick={() => HandleSubmit()}>取得</Button> */}
            </Grid>
            <MainTable billingList={BillingStatusState?.billing_list} month={month} />
            {BillingStatusState.total_amount ? <Box>合計金額 {BillingStatusState.total_amount}円</Box> : ""}
        </React.Fragment>
    );
};

const PageMove = (arg, month, history) => {
    const params = {
        data_type: "請求明細表",
        tell: arg.row.tel,
        month: month,
    };
    const urlSearchParam = new URLSearchParams(params).toString();
    history.push(`/IndividualUsageView` + "/?" + urlSearchParam);
};

const MainTable = (props) => {
    const history = useHistory();
    const { billingList = [], month = "2023-09" } = props;
    const BillingStatusState = useSelector((state) => state.BillingStatus);

    const columns = [
        { field: "tel", headerName: "電話番号", minWidth: 150 },
        { field: "user_name", headerName: "氏名", minWidth: 150 },
        { field: "company_name", headerName: "会社名", minWidth: 150 },
        { field: "division_name", headerName: "部署名", flex: true, minWidth: 200 },
        { field: "cost_code", headerName: "経費負担コード" },
        { field: "career", headerName: "キャリア" },
        { field: "model", headerName: "機種" },
        { field: "price", headerName: "請求額合計", minWidth: 200 },
        // { field: "account_code", headerName: "勘定コード" },
        // { field: "plan", headerName: "プラン", minWidth: 400 },
        // { field: "9", headerName: "基本料金" },
        // { field: "10", headerName: "通話料金合計" },
        // { field: "11", headerName: "発信通話料" },
        // { field: "12", headerName: "パケット通信料" },
        // { field: "13", headerName: "データ通信料" },
        // { field: "14", headerName: "割引額" },
        // { field: "15", headerName: "その他通信料" },
        // { field: "16", headerName: "国際電話" },
        // { field: "17", headerName: "オプション合計" },
        // { field: "18", headerName: "その他合計" },
        // { field: "19", headerName: "請求金額(税込)" },
        // { field: "20", headerName: "請求金額(うち消費税)" },
        // { field: "21", headerName: "請求金額(うち非課税)" },
        // { field: "22", headerName: "修正コメント" },
        {
            field: "",

            headerName: "",
            renderCell: (params) => (
                <Box>
                    <Button onClick={() => PageMove(params, month, history)}>詳細</Button>
                </Box>
            ),
        },
    ];

    return (
        <Box
            sx={{
                height: 900,
                width: "100%",
                mt: "1rem", // minWidth: "100%"
                display: "grid",
            }}
        >
            <DataGrid
                rows={billingList}
                columns={columns}
                rowCount={BillingStatusState.total}
                // pageSize={10}
                // paginationMode="server"
                // initialState={{
                //     pagination: { paginationModel: { pageSize: 10 } },
                // }}
                onPageChange={(page) => {
                    console.log(page);
                }}
                // pageSizeOptions={[10]}
                // paginationMode="server"
                // sortingMode="server"
                // rowsPerPageOptions={[limit]}
                // disableColumnMenu

                // initialState={{
                //     columns: {
                //         columnVisibilityModel: {
                //             Info_id: false,
                //         },
                //     },
                // }}
                disableCellSelectionOnClick
                disableSelectionOnClick
                pagination
                // onRowClick={(e) => HandleRowClick(e.row)}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            />
        </Box>
    );
};
export default BillingContentStatus;
