import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";
import { width } from "@mui/system";

import { PostCancellation, Application_set, GetModelData } from "~slices/Application";
import SelectUser from "~/components/block/SelectUser";
import { SelectModel } from "~/components/block/SelectModel";
import { SerchModelData } from "~/components/block/SerchModelData";

import { InputDestination } from "~/components/block/InputDestination";

import { addDays, addMonths, format } from "date-fns";

import { SelectApprover } from "~/components/block/SelectApprover";
import { useHistory } from "react-router";

const Cancellation = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const ApplicationState = useSelector((state) => state.Application);

    const [change_user_flag, setAnotherUser] = React.useState(false);
    const [selected_user, setSelectedUser] = React.useState({});

    const HandleSubmit = (e) => {
        e.preventDefault();

        const elem = e.target.elements;

        const model = elem?.model?.value;

        if (!ApplicationState.selected_tel) {
            alert("電話番号を登録してください。");
            return;
        }
        if (!ApplicationState.approver_name) {
            alert("承認者を登録してください。");
            return;
        }
        if (!ApplicationState.selected_tel) {
            alert("電話番号から現在のご利用機種を登録してください。");
            return;
        }

        dispatch(
            PostCancellation({
                tel: ApplicationState.selected_tel,
                model: model,
                remarks: elem?.remarks?.value,
                // change_user_flag: change_user_flag,
                // change_name: selected_user?.DisplayName,
                // change_email: selected_user?.Address,
                // change_company: selected_user?.Company,
                // change_department: selected_user?.Department,
                hope_date: elem?.hope_date?.value,
                approver_name: ApplicationState.approver_name,
                approver_email: ApplicationState.approver_email,
            })
        ).then((res) => {
            if (res.payload.result == "OK") {
                alert("申請が完了しました。");
                history.push("/");
            }
        });
    };

    const HandleSelectUser = (select_user) => {
        setSelectedUser(select_user);
    };

    // const [disabledCheck, setDisabledCheck] = React.useState(false);

    const after_day = (arg) => {
        // const day = addDays(new Date(), 10);
        const day = addMonths(new Date(), 1);

        const format_day = format(day, "yyyy-MM-dd");

        return format_day;
        // // this_day.setDate(this_day.getDate() + 11);
        // this_day.setMonth(this_day.getMonth() + 1);
        // // const ten_day = today.setDate(today.getDate() + 10);

        // const year = this_day.getFullYear();
        // const month = this_day.getMonth();
        // const day = this_day.getDate();
        // const string_month = month.toString().padStart(2, "0");
        // return `${year}-${string_month}-${day}`;
    };

    return (
        <React.Fragment>
            <Typography variant="h4">解約申請</Typography>
            電話事業者との契約を解除する際の申請です
            <Caution />
            <div className="essential" style={{ margin: "10px 0 5px 10px" }}>
                <span className="kome">※</span> は、必須項目ですので、必ず記入してください
            </div>
            <Typography variant="h5">現在ご利用機種</Typography>
            <Box sx={{ background: "#ececec", p: 2, mb: 2 }}>
                <SerchModelData />
            </Box>
            <form onSubmit={HandleSubmit}>
                <Box sx={{ background: "#ececec", p: 2 }}>
                    <Box sx={{ mt: 1, mb: 1 }}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            name="hope_date"
                            label="解約希望日"
                            defaultValue={after_day()}

                        />
                    </Box>
                    <Box>
                        申請理由/特記事項
                        <TextField
                            InputLabelProps={{ shrink: true, min: "2023-11-25" }}
                            type="text"
                            label=""
                            name="remarks"
                            multiline
                            rows={4}
                            fullWidth
                        // helperText="※ 名前は部分一致で検索します。"
                        />
                    </Box>
                </Box>
                <Box sx={{ background: "#ececec", p: 2 }}>
                    ※解約違約金 <br />
                    ※4年利用未満の場合、解約金20,000円がかかります。
                </Box>
                {/* <InputDestination /> */}
                <Typography variant="h5">承認者</Typography>
                <Box sx={{ backgroundColor: "#ececec", p: "1rem", mt: 1 }}>
                    <SelectApprover />
                </Box>
                <Box
                    sx={{
                        pt: "1rem",
                    }}
                >
                    <Button variant="contained" size="large" sx={{ height: "2.5rem" }} type="submit">
                        申請
                    </Button>
                </Box>
            </form>
        </React.Fragment>
    );
};

const Caution = () => {
    return (
        <React.Fragment>
            <div style={{ border: "1px solid #FF6600", margin: "5px 0 5px 0" }} className="textselect">
                <div
                    style={{
                        textDecoration: "underline",
                        fontSize: 14,
                        margin: "5px 0 10px 10px",
                    }}
                >
                    解約する場合の手続きについて
                </div>
                <div style={{ margin: "0 0 5px 5px", width: "80%" }}>
                    返却時は、以下に留意ください。
                    <br />
                    必ずiPhoneのデータを消去して返却ください。消去方法が不明な場合は情報システムヘルプデスクへ問合せください。
                    <br />
                    <span id="lbl" style={{ color: "Red" }}>
                        解約から1ヶ月以内に返却してください。
                        <br />
                        返却されない場合、未返却損害金（5万円）が請求されます。
                    </span>
                    <div style={{ marginLeft: 20 }}>
                        <br />
                        【iPhoneレンタル機の返却先】 <br />
                        〈ヤマト便の場合〉
                        <br />
                        〒243-0303
                        <br />
                        027-600 厚木物流システム支店気付
                        <br />
                        KDDI株式会社 「SL レンタルチーム」係
                        <br />
                        <br />
                        〈ヤマト便以外の場合〉
                        <br />
                        〒252-0146 神奈川県相模原市緑区大山町4番7号ロジポート橋本303区画
                        <br />
                        KDDI株式会社 東日本物流キッティングセンターSL レンタルチーム係
                        <br />
                        <br />
                        ※下記6社であれば、【着払い】にて送付受付可能です。それ以外の運送会社の場合は【元払い】での発送となります。
                        <br />
                        【ヤマト運輸】【佐川急便】【ゆうパック】【福山通運】【西濃運輸】【ＳＳＸ】
                        <br />
                        <br />
                    </div>

                    【GRATINA・モバイルWi-Fiの返却先について】
                    <div style={{ marginLeft: 20 }}>
                        <br />
                        <span id="Label1" style={{ color: "Red" }}>
                            返却時は端末廃棄依頼書が必要になりますので以下のリンクからファイルをダウンロードの上、
                            必要事項を記入し、端末送付時に同梱してください。
                        </span>
                        <br />
                        <a href="Notes://Notes_KS2/492563AB002C2786/B12B5577B74E88D949257E30000584D0/5C7B292E7278764E4925898A00104CEB">
                            Notes://Notes_KS2/492563AB002C2786/B12B5577B74E88D949257E30000584D0/5C7B292E7278764E4925898A00104CEB
                        </a>
                        <br />
                        <br />
                        <span id="Label1" style={{ color: "Red" }}>
                            ※事前に返却先についてヘルプデスクまたは栗田工業 DX推進部などから指示を受けている場合は、<br />
                            そちらの指示通りにご対応ください。
                        </span>
                        <br />    <br />

                        {'<発送先>'}
                        <br />
                        ------------------------------------------------------------<br />
                        〒102-8460 東京都千代田区飯田橋3-10-10 ガーデンエアタワー6F<br />
                        KDDI株式会社 モバイルサービス部 端末リサイクルセンター係<br />
                        電話番号：03-6365-1171<br />
                        ------------------------------------------------------------<br />


                    </div>
                </div>
                <div style={{ margin: "0 0 5px 5px", width: "80%" }}>
                    ※ 着払いはできません。元払いでお願いします。
                    <br />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Cancellation;
