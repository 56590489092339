import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { GetAffiliationSelectList, UserAffiliation_set } from "~slices/UserAffiliation";

import {
    Grid,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Autocomplete,
} from "@mui/material";

const SelectUser = (props) => {
    const { onSet, buttonLabel = "選択",
        mode = "user",
        inputCostCode = false,
        blancCompany = false,
        inputName = false,
        inputTel = false
    } = props;
    const UserAffiliationState = useSelector((state) => state.UserAffiliation);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(GetAffiliationSelectList());
    }, []);

    const [company, set_company] = React.useState(false);

    const [cost_code, set_cost_code] = React.useState("");

    const [tel, set_tel] = React.useState("");
    const [user_name, set_user_name] = React.useState("");

    const [department, set_department] = React.useState(false);
    const [inputValue, setInputValue] = React.useState("");

    const [email, set_email] = React.useState(false);
    const [select_user, setSelectUser] = React.useState(false);
    const [select_index, setSelectIndex] = React.useState(false);
    const ChengeSelectCompany = (e) => {
        set_company(e.target.value);
        set_department("");
        dispatch(GetAffiliationSelectList({ company: e.target.value }));
    };

    const ChengeSelectDepartment = (value) => {
        set_department(value.department_name);

        if (mode == "user") {
            dispatch(GetAffiliationSelectList({ company: company, department: value.department_name }));
        }
    };
    const ChengeSelectUser = (e) => {
        setSelectIndex(e.target.value);

        const selected_user_data = UserAffiliationState?.user_list[e.target.value];

        set_email(selected_user_data.Address);

        setSelectUser(selected_user_data);
    };
    return (
        <>
            <Stack direction='column' justifyContent="flex-start" alignItems="flex-start" spacing={2}>


                <Grid row >


                    {inputName ? (
                        <>
                            <TextField

                                value={user_name}
                                label="名前"
                                onChange={(e) => set_user_name(e.target.value)}
                                sx={{ minWidth: "10rem", marginRight: '1rem' }}
                                helperText='※前方一致で検索します。'
                            // inputProps={{ maxLength: 5, pattern: "^[a-zA-Z0-9_]+$" }}
                            ></TextField>
                        </>
                    ) : (
                        ""
                    )}

                    {inputTel ? (
                        <>
                            <TextField
                                value={tel}
                                label="電話番号"
                                onChange={(e) => set_tel(e.target.value)}
                                sx={{ minWidth: "10rem" }}
                            // inputProps={{ maxLength: 5, pattern: "^[a-zA-Z0-9_]+$" }}
                            ></TextField>

                        </>
                    ) : (
                        ""
                    )}
                </Grid>
                <TextField
                    select
                    value={company}
                    label="会社"
                    onChange={(e) => {
                        ChengeSelectCompany(e);
                        set_department('');
                    }}
                    sx={{ minWidth: "20rem" }}
                      helperText='※会社名＋経費負担コードでも検索ができます'
                >
                    {blancCompany ? <MenuItem value="">全社</MenuItem> : <></>}
                    {UserAffiliationState?.company_list &&
                        UserAffiliationState?.company_list.map((company_data) => (
                            <MenuItem key={company_data.company_name} value={company_data.company_name}>
                                {company_data.company_name}
                            </MenuItem>
                        ))}
                </TextField>

                {/* {inputValue} */}
                <Grid row >

                    {mode == "company_only" ? (
                        <>
                            {company || blancCompany ? (
                                <Button
                                    onClick={() =>
                                        onSet({
                                            company: company,
                                        })
                                    }
                                    variant="contained"
                                    size="large"
                                >
                                    {buttonLabel}
                                </Button>
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        <>
                            {UserAffiliationState?.department_list?.length ? (
                                <Box>



                                    <Autocomplete
                                        disablePortal
                                        freeSolo
                                        id="combo-box-demo"
                                        options={UserAffiliationState?.department_list}
                                        sx={{ minWidth: '500px' ,width:'70vw'}}
                                        // value={department}

                                        onChange={(event, newValue) => {
                                            ChengeSelectDepartment(newValue);
                                        }}
                                        // blurOnSelect
                                        onBlur={(e) => {
                                            // set_email(false);

                                            // set_department(e.target.value);

                                            // if (e.target.value.length < 2) {
                                            //     return;
                                            // }
                                            ChengeSelectDepartment({ department_name: e.target.value });
                                            // if (!e.target.value) {
                                            //     ChengeSelectDepartment;
                                            // }
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        // inputValue={department}
                                        // onInputChange={(e, changeValue) => {
                                        //     ChengeSelectDepartment(e);
                                        // }}
                                        // onChange={(e) => ChengeSelectDepartment(e)}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            label="部署"
                                            id='department_name_input'
                                            helperText="※ 部署名は一部入力で部分検索できます" />}
                                        getOptionLabel={(option) => option.department_name}

                                    />

                                </Box>

                            ) : (
                                ""
                            )}
                        </>
                    )}

                    {inputCostCode ? (
                        <>
                            <TextField
                                value={cost_code}
                                label="経費負担コード"
                                onChange={(e) => set_cost_code(e.target.value)}
                                sx={{ minWidth: "10rem" }}
                                inputProps={{ maxLength: 5, pattern: "^[a-zA-Z0-9_]+$" }}
                                helperText='※会社名を必ず選択してください。'
                            ></TextField>
                        </>
                    ) : (
                        ""
                    )}
                    {mode == "company" ? (
                        <>
                            {company ? (
                                <Button
                                    onClick={() =>
                                        onSet({
                                            user_list: UserAffiliationState?.user_list,
                                            department: department,
                                            company: company,
                                        })
                                    }
                                    variant="contained"
                                    size="large"
                                >
                                    {buttonLabel}
                                </Button>
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        <>
                            {mode == "user" ? (
                                <>
                                    {UserAffiliationState?.user_list?.length ? (
                                        <TextField
                                            select
                                            value={select_index}
                                            label="社員"
                                            onChange={ChengeSelectUser}
                                            sx={{ minWidth: "20rem" }}
                                        >
                                            {UserAffiliationState?.user_list &&
                                                UserAffiliationState?.user_list.map((user_data, i) => (
                                                    <MenuItem key={user_data.UpdateDisplayName} value={i}>
                                                        {user_data.UpdateDisplayName}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    ) : (
                                        ""
                                    )}

                                    {email ? (
                                        <Button onClick={() => onSet(select_user)} variant="contained" size="large">
                                            {buttonLabel}
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                <>
                                    {department || (company && inputCostCode && cost_code) || user_name || tel ? (
                                        <Button
                                            onClick={() =>
                                                onSet({
                                                    user_list: UserAffiliationState?.user_list,
                                                    department: department,
                                                    company: company,
                                                    cost_code: cost_code,
                                                    user_name: user_name,
                                                    tel: tel,
                                                })
                                            }
                                            variant="contained"
                                            size="large"
                                            sx={{ ml: '2rem', mt: '0.5rem' }}
                                        >
                                            {buttonLabel}
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Grid>
            </Stack>
        </>
    );
};

export default SelectUser;
