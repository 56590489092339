
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
};
export const Upload = (arg) => {
    return AsyncFunction({
        type_name: "UploadCSVMbile/csvデータのアップロード",
        method: "post",
        url: "/api/UploadCSVMbile",
        params: arg,
        content_type: "multipart/form-data",
    });
};
// Sliceを生成する
const slice = createSlice({
    name: "UploadCSVMbile",
    initialState,
    reducers: {
        initialize: (state) => (state = initialState),
        UploadCSVMbile_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("UploadCSVMbile") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, UploadCSVMbile_set } = slice.actions;
