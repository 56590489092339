import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    TextField,
    Grid,
    Container,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    Typography,
    Autocomplete,
    Button,
    Box,
} from "@mui/material";

import { SerchStaffStatus, StaffStatus_set } from "~slices/StaffStatus";

import { DataGrid, jaJP } from "@mui/x-data-grid";

import SelectUser from "~/components/block/SelectUser";

const StaffStatus = () => {
    const dispatch = useDispatch();

    const StaffStatusState = useSelector((state) => state.StaffStatus);
    const user_list = StaffStatusState.user_list;

    // React.useEffect(() => {
    //     const params = {
    //         destination: destination,
    //     };
    //     dispatch(GetChat(params)).then((data) => {
    //         const chat_bottom = document.getElementById("chat_bottom");
    //         const content_position = chat_bottom.getBoundingClientRect();
    //         window.scrollTo(0, content_position.top);
    //     });
    // }, []);

    const HandleSubmit = (e) => {
        e.preventDefault();
        // console.log(e.target.month.value);
        // console.log(e.target.text.value);

        dispatch(SerchStaffStatus());
    };

    const columns = [
        { headerName: "氏名", field: "UpdateDisplayName", minWidth: 150 },
        { headerName: "Eメールアドレス", field: "UpdateAddress", minWidth: 250 },
        { headerName: "会社名", field: "Company", minWidth: 200 },
        { headerName: "部署名", field: "Department", flex: true },
        { headerName: "役職", field: "Title" },
    ];

    const SetUserList = (selected_data) => {
        dispatch(StaffStatus_set(["user_list", selected_data.user_list]));

        dispatch(SerchStaffStatus(selected_data));
    };
    return (
        <React.Fragment>
            <Typography variant="h4">登録情報表示</Typography>
            <Typography>社員登録状況のご確認です</Typography>
            <form noValidate autoComplete="off" onSubmit={HandleSubmit} name="chat_input_form">
                <Container sx={{ p: "1rem", border: "solid 1px gray", borderRadius: "0.5rem" }}>
                    {/* <Box sx={{ mb: "0.5rem" }}>
                        <TextField InputLabelProps={{ shrink: true }} type="month" name="month" label="日付" />
                    </Box> */}

                    <SelectUser mode="department" onSet={(arg) => SetUserList(arg)} buttonLabel="検索" />
                    {/* <Box>
                        <Button variant="contained" size="large" sx={{ height: "3.5rem" }} type="submit">
                            検索
                        </Button>
                    </Box> */}
                </Container>
            </form>

            <Box
                sx={{
                    height: 800,
                    width: "100%",
                    mt: "1rem", // minWidth: "100%"
                }}
            >
                <DataGrid
                    // getRowId={(row) => row.id}
                    rows={user_list}
                    columns={columns}
                    // rowCount={party_list_total}
                    // pageSize={limit}
                    // onPageChange={setPage}
                    // paginationMode="server"
                    // sortingMode="server"
                    // rowsPerPageOptions={[limit]}
                    // disableColumnMenu
                    disableSelectionOnClick
                    pagination
                    getSelectedRows={(test) => console.log(test)}
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
        </React.Fragment>
    );
};

export default StaffStatus;
