import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,

} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";
import { width } from "@mui/system";

import { PostNewApplication, Application_set } from "~slices/Application";
import SelectUser from "~/components/block/SelectUser";

import { SelectModel } from "~/components/block/SelectModel";
import { InputDestination } from "~/components/block/InputDestination";

import { SelectApprover } from "~/components/block/SelectApprover";

import CheckModal from "~/components/block/CheckModal";
import { useHistory } from "react-router";



const NewApplication = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const ApplicationState = useSelector((state) => state.Application);
    const [another_user, setAnotherUser] = React.useState(false);

    const [selected_user, setSelectedUser] = React.useState();
    const [user_type, setUserType] = React.useState("個人");

    const [open, setOpen] = React.useState(false);
    const [sendData, setSendData] = React.useState(false);
    const [checkItem, setCheckItem] = React.useState([]);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        const model = elem?.model?.value;

        if (!ApplicationState.approver_name) {
            alert("承認者を登録してください。");
            return;
        }

        if (another_user && !selected_user?.Address) {
            alert("使用者を登録してください。");
            return;
        }

        setOpen(true);

        setSendData({
            model: model,
            another_user: another_user,
            // account_code: elem?.account_code?.value,
            remarks: elem?.remarks?.value,
            cost_code: elem?.cost_code?.value,
            user_email: selected_user?.Address,
            user_name: selected_user?.DisplayName,
            user_department: selected_user?.Department,
            user_company: selected_user?.Company,
            user_type: user_type,
            sending_name: elem?.sending_name?.value,
            sending_post_code: elem?.sending_post_code?.value,
            sending_address: elem?.sending_address?.value,
            sending_tel: elem?.sending_tel?.value,
            usage: elem?.usage?.value,
            approver_name: ApplicationState.approver_name,
            approver_email: ApplicationState.approver_email,
        });

        setCheckItem([
            { label: "モデル", value: model, name: "model" },

            // { label: "勘定コード", value: elem?.account_code?.value, name: "account_code" },
            { label: "経費負担コード", value: elem?.cost_code?.value, name: "cost_code" },

            { label: "使用者区分", value: user_type, name: "user_type" },
            { label: "送付先名", value: elem?.sending_name?.value, name: "sending_name" },
            { label: "送付先郵便番号", value: elem?.sending_post_code?.value, name: "sending_post_code" },
            { label: "送付先アドレス", value: elem?.sending_address?.value, name: "sending_address" },
            { label: "送付先電話番号", value: elem?.sending_tel?.value, name: "sending_tel" },

            { label: "承認者名", value: ApplicationState.approver_name, name: "approver_name" },
            { label: "承認者メールアドレス", value: ApplicationState.approver_email, name: "approver_email" },
            { label: "利用用途用途", value: elem?.usage?.value, name: "usage" },
            { label: "備考", value: elem?.remarks?.value, name: "remarks" },

            { label: "使用者", value: another_user ? "別ユーザーが使用" : "申請者が使用", name: "another_user" },
            { label: "(別利用者)メールアドレス", value: selected_user?.Address, name: "user_email" },
            { label: "(別利用者)利用者名前", value: selected_user?.DisplayName, name: "user_name" },
            { label: "(別利用者)部署", value: selected_user?.Department, name: "user_department" },
            { label: "(別利用者)会社", value: selected_user?.Company, name: "user_company" },
        ]);
    };

    const HandleApplication = () => {
        setOpen(false);
        dispatch(PostNewApplication(sendData)).then((data) => {

            if (data?.payload?.result == 'OK') {

                alert("申請完了しました");
                history.push("/");

            }

        });
    };

    const HandleSelectUser = (select_user) => {
        setSelectedUser(select_user);
    };

    return (
        <React.Fragment>
            <Typography variant="h4" sx={{ mb: 2 }}>
                新規端末申請
            </Typography>

            <CheckModal
                open={open}
                handleClose={() => setOpen(false)}
                onApplication={HandleApplication}
                checkItem={checkItem}
            />

            <Typography variant="h5">①ご利用希望機器</Typography>
            <form onSubmit={HandleSubmit}>
                <Box sx={{ backgroundColor: "#ececec", p: "1rem" }}>
                    <SelectModel />

                    <Typography sx={{ color: "red" }}>【iPhoneの新規申請時の留意点】</Typography>
                    <Box sx={{ color: "red" }}>
                        <ul>
                            <li>
                                申請後のキャンセルは原則解約手数料が発生します。
                            </li>
                            <li>
                                iPhoneSE3の新品またはiPhoneSE2/11のリユース品となります。（KDDIの在庫状況によって変わりますので選択できません）
                            </li>
                            <li>
                                電源アダプタおよびケーブルは新品とリユース品で同梱されているかが変わります。以下の通り費用が掛かりますのでご了承ください。
                            </li>
                        </ul>
                        <ol>
                            <li>
                                新品の場合：電源ケーブルが同梱されているため電源アダプタのみ費用がかかります。（1,980円）
                            </li>
                            <li>
                                リユース品の場合：電源アダプタとケーブルが同梱されていないため双方の費用がかかります。
                                <br />
                                （電源アダプタ：1,980円、ケーブル：2,480円）
                            </li>
                        </ol>
                    </Box>
                    <Typography>
                        WiFiルータはKDDIよりも安価で利用できる通信量が大きいSoftbankのWiFiルータを推奨しています。
                        <br />
                        利用される場合は、以下へ連絡いただければと思います。
                        <br />
                        【連絡先】 s.ogawa69@kurita-water.com
                        <br />
                        ■KDDIのWiFiルータ：月額費用：1,890円/月、データ通信量：7GB/月
                        <br />
                        ■SoftbankのWiFiルータ：月額費用：1,180円/月、データ通信量：50GB/月

                    </Typography>

                    <Box>

                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label="経費負担コード"
                            name="cost_code"
                            inputProps={{ maxLength: 5, pattern: "^[a-zA-Z0-9_]+$" }}
                            // size="small"
                            sx={{ mt: "1rem" }}
                            required
                        />
                        <br />
                        ※栗田工業の方は3桁の経費負担コードを入力ください。<br />
                        ※栗田工業以外で経費負担コードの入力が必須ではない関係会社は「0000」など任意の数字を入力下さい。
                    </Box>
                    {/* <Box sx={{
                        margin: '8px',
                        padding: '8px',
                        background: '#d6d6d6',
                        maxWidth: 'fit-content'
                    }}>
                        <Box>
                            ※経費負担コード変更先の部署は変更承諾済みですか？
                        </Box>
                        <FormControlLabel control={<Checkbox required />} label="はい。変更先の部署の承諾済みです。" />
                    </Box> */}
                    {/* <Typography sx={{ mt: "1rem" }}>申請理由/特記事項</Typography> */}


                    <Box>

                        <TextField
                            InputLabelProps={{ shrink: true }}
                            sx={{ mt: "1rem" }}
                            type="text"
                            label="利用用途"
                            name="usage"
                            multiline
                            rows={1}
                            fullWidth />
                        <br />
                        ※課などでの共有端末や、使用者以外が利用する貸与端末の場合、利用用途をご記載ください。
                    </Box>
                    <Box>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            sx={{ mt: "1rem" }}
                            type="text"
                            label="申請理由/特記事項"
                            name="remarks"
                            multiline
                            rows={4}
                            fullWidth
                        // helperText="※ 名前は部分一致で検索します。"
                        />
                        {/* <Button variant="contained" size="large" sx={{ height: "3.5rem" }} type="submit">
                            検索
                        </Button> */}
                    </Box>


                    <FormControlLabel
                        control={<Switch checked={another_user} onChange={(e) => setAnotherUser(e.target.checked)} />}
                        label="申請者と使用者が異なる"
                    />
                </Box>

                <InputDestination titleNum="②" />

                {another_user ? (
                    <>
                        <Typography variant="h5" sx={{ mt: 2 }}>
                            ③使用者の情報
                        </Typography>
                        <Box> ［ 申請者と使用者が異なる場合は、下記にご記入ください ］</Box>

                        <Box sx={{ backgroundColor: "#ececec", p: "1rem" }}>
                            {/* <FormControl component="fieldset">
                                <FormLabel component="legend">使用者種別</FormLabel>
                                <RadioGroup
                                    aria-label="user_type"
                                    name="user_type"
                                    value={user_type}
                                    onChange={(e) => setUserType(e.target.value)}
                                >
                                    <FormControlLabel value="個人" control={<Radio />} label="個人で使用" />
                                    <FormControlLabel value="組織" control={<Radio />} label="組織で使用" />
                                </RadioGroup>
                            </FormControl> */}

                            {/* ※ 使用者種別 */}
                            {/* <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="個人で使用" />
                        <FormControlLabel control={<Checkbox />} label="組織で使用" />
                    </FormGroup> */}
                            {selected_user ? (
                                <>
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            background: "#ececec",
                                            m: 2,
                                            p: 2,
                                            borderTop: "1px solid black",
                                            borderBottom: "1px solid black",
                                        }}
                                    >
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    メールアドレス
                                                </Grid>
                                                <Grid item xs>
                                                    {selected_user?.Address}
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    名前
                                                </Grid>
                                                <Grid item xs>
                                                    {selected_user?.DisplayName}
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    所属
                                                </Grid>
                                                <Grid item xs>
                                                    {selected_user?.Company} <br />
                                                    {selected_user?.Department}
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardActions>
                                            <Button onClick={() => setSelectedUser("")}>変更</Button>
                                        </CardActions>
                                    </Card>
                                </>
                            ) : (
                                <SelectUser onSet={HandleSelectUser} />
                            )}
                            {/* <Box>
                                <FormLabel>社員メールアドレス</FormLabel>
                            </Box>
                            <Box>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    label=""
                                    name="text"
                                    helperText="※ 名前は部分一致で検索します。"
                                    size="small"
                                />
                                <Button variant="contained" size="large" sx={{ height: "2.5rem" }}>
                                    検索
                                </Button>
                            </Box> */}

                            {/* <FormControl sx={{ mt: "1rem" }} size="small" required name="account_code">
                                <InputLabel id="select-model-label">勘定コード</InputLabel>
                                <Select
                                    name="account_code"
                                    sx={{ width: "20rem" }}
                                    fullWidth
                                    // labelId="select-number_BottleKeep-label"
                                    label="勘定コード"
                                    // value={Number(number_bottle_keep)}
                                    // onChange={handleNumberBottleKeep}
                                    defaultValue={"64700"}
                                >
                                    <MenuItem value="64700">64700</MenuItem>
                                    <MenuItem value="84700">84700</MenuItem>
                                </Select>
                            </FormControl> */}
                        </Box>
                    </>
                ) : (
                    ""
                )}

                <Typography variant="h5" sx={{ mt: 2 }}>
                    承認者
                </Typography>

                <Box sx={{ backgroundColor: "#ececec", p: "1rem", mt: 1 }}>
                    <SelectApprover />
                </Box>
                <Box
                    sx={{
                        pt: "1rem",
                    }}
                >
                    <Button variant="contained" size="large" sx={{ height: "2.5rem" }} type="submit">
                        申請
                    </Button>
                </Box>
            </form>
        </React.Fragment>
    );
};

export default NewApplication;
