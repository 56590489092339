import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box"

import InputField from "~/components/inputs/InputField";

import { UserLogin, SsoTokenLogin } from "~slices/Information";
import { Link } from "react-router-dom";

import { useHistory } from "react-router";

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [inputEmail, setInputEmail] = useState("");

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        const params = {
            email: elem?.email?.value,
            password: elem?.password?.value,
        };
        console.log(params);

        dispatch(UserLogin(params)).then((res) => {
            if (res.payload.result == "OK") {
                history.push("/");
            }
        });
    };

    // useEffect(() => {
    //     const has_param = /user_token=/.test(window.location.href);
    //     if(has_param) {
    //         const url = window.location.href;
    //         const match = url.match(/user_token=([^&]*)/);
    //         const user_token = match ? match[1] : null;
    //         console.log(user_token);
    //         const params = {
    //             user_token: user_token,
    //         };
    //         dispatch(SsoTokenLogin(params)).then((res) => {
    //             if(res.payload.result =="OK") {
    //                 history.push("/");
    //             }
    //         });
    //     }
    // }, []);
    const HandleSsoLogin = async(e) => {
        e.preventDefault();
        // window.location.href = "https://bizlink.kurita-sys.jp/saml2/b4d3b0ef-f859-4bea-8842-44e75e651617/login";
        window.location.href = "https://tel.kurita-sys.jp/saml2/5839d4d5-8151-42e7-870d-11da135dac0e/login";
    }

    return (
        <React.Fragment>
            <Container>
                <form onSubmit={HandleSubmit}>
                    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                        <Typography variant="h4" gutterBottom>
                            ログイン
                        </Typography>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                // background: "linear-gradient(90deg, rgb(22, 135, 237), rgb(143, 184, 225))",
                                // background: "linear-gradient(-20deg, #5862d5 0%, #91d1f6 100%)",
                                // background: "radial-gradient(rgb(169, 214, 255), rgb(0, 80, 159))",
                                border: "solid 1px",
                                borderRadius: 2,
                                p: 2,
                                maxWidth: "350px",
                                width: "100%",
                            }}
                        >
                            <InputField
                                type="email"
                                name="email"
                                label="メールアドレス"
                                required
                                value = {inputEmail}
                                onChange={(e) => setInputEmail(e.target.value)}
                            />
                            <InputField
                                type="password"
                                name="password"
                                label="パスワード"
                                required
                            />
                            {/* {inputEmail == "s.tokuda56@kurita-water.com" ? ( */}
                                <Box sx={{p: "0.3rem", mt: 1 , width: "100%",}} display="flex" justifyContent="space-around">
                                    <Button variant="contained" type="submit">
                                        ログイン
                                    </Button>
                                    <Button variant="contained" type="submit" onClick={HandleSsoLogin}>
                                        SSOログイン
                                    </Button>
                                </Box>
                            {/* ) : (
                                <Button variant="contained" type="submit">
                                    ログイン
                                </Button>
                            )} */}
                        </Grid>
                    </Grid>
                    <Grid>
                        {/* <Link to="/signup">新規登録はこちら</Link> */}
                        {/* <Link to="/signup">パスワードを忘れた?</Link> */}
                    </Grid>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default Login;
