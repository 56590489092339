

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    mobile_list: [],

};

export const GetMobileList = (arg) => {
    return AsyncFunction({
        type_name: "MobileManagement/回線一覧取得",
        method: "post",
        url: "/api/GetMobileList",
        params: arg,
    });
};
export const GetAllCSV = (arg) => {
    return AsyncFunction({
        type_name: "MobileManagementCSV/回線マスタ全件csvデータの取得",
        method: "post",
        url: "/api/GetAllCSV",
        params: arg,
    });
};


// Sliceを生成する
const slice = createSlice({
    name: "MobileManagement",
    initialState,
    reducers: {
        MobileManagement_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("MobileManagement") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.mobile_list) {
                    state.mobile_list = payload?.mobile_list;
                }
            }
        )
            .addMatcher(
                (action) => action.type.match("MobileManagementCSV") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    //CSVデータ
                    const data = action.payload;

                    //ダウンロードするCSVファイル名を指定する
                    const filename = "download.csv";

                    //BOMを付与する（Excelでの文字化け対策）
                    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
                    //Blobでデータを作成する
                    const blob = new Blob([bom, data], { type: "text/csv" });

                    //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
                    if (window.navigator.msSaveBlob) {
                        window.navigator.msSaveBlob(blob, filename);
                        //その他ブラウザ
                    } else {
                        //BlobからオブジェクトURLを作成する
                        const url = (window.URL || window.webkitURL).createObjectURL(blob);
                        //ダウンロード用にリンクを作成する
                        const download = document.createElement("a");
                        //リンク先に上記で生成したURLを指定する
                        download.href = url;
                        //download属性にファイル名を指定する
                        download.download = filename;
                        //作成したリンクをクリックしてダウンロードを実行する
                        download.click();
                        //createObjectURLで作成したオブジェクトURLを開放する
                        (window.URL || window.webkitURL).revokeObjectURL(url);
                    }
                }
            );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { MobileManagement_set } = slice.actions;
