import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Chip,
    LinearProgress,
    Input,
    styled,
    Card,
    Paper,
    Hidden,
} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";




import { GetMobileList, GetAllCSV } from "~slices/MobileManagement";

import SelectUser from "~/components/block/SelectUser";

const MobileManagement = () => {
    const dispatch = useDispatch();
    const MobileManagementState = useSelector((state) => state.MobileManagement);
    const InformationState = useSelector((state) => state.Information);


    const SetUserList = (list) => {


        dispatch(GetMobileList({ ...list }));

    }
    const HandleGetAllCSV = (list) => {


        dispatch(GetAllCSV());

    }


    const columns = [
        // { field: "id", headerName: "id", minWidth: 150, Hidden },
        { field: "tel", headerName: "電話番号", minWidth: 150 },
        { field: "user_name", headerName: "氏名", minWidth: 150 },
        { field: "company_name", headerName: "会社名", minWidth: 150 },
        { field: "division_name", headerName: "部署名", minWidth: 200, flex: true },
        { field: "cost_code", headerName: "経費負担コード", minWidth: 150 },
        { field: "usage", headerName: "利用用途", minWidth: 150 },
        { field: "reservation_month", headerName: "反映日", minWidth: 150 },
    ]

    return (
        <React.Fragment>
            <Typography variant="h4">回線情報表示</Typography>
            <Typography>回線マスター管理を管理します。</Typography>


            {InformationState?.user_type == "admin" ? (
                <Button onClick={HandleGetAllCSV}>全回線CSVダウンロード</Button>
            ) : ''}
            <br />
            <SelectUser
                mode="department"
                onSet={(list) => SetUserList(list)}
                inputCostCode
                buttonLabel="検索"
                inputName
                inputTel
                blancCompany
            />
            <Box sx={{ color: 'gray', fontSize: 'small' }}></Box>
            <Box
                sx={{
                    height: 900,
                    width: "100%",
                    mt: "1rem", // minWidth: "100%"
                    display: "grid",
                }}
            >
                <DataGrid
                    rows={MobileManagementState.mobile_list}
                    columns={columns}
                    // rowCount={BillingStatusState.total}
                    // pageSize={10}
                    // paginationMode="server"
                    // initialState={{
                    //     pagination: { paginationModel: { pageSize: 10 } },
                    // }}
                    // onPageChange={(page) => {
                    //     console.log(page);
                    // }}
                    // pageSizeOptions={[10]}
                    // paginationMode="server"
                    // sortingMode="server"
                    // rowsPerPageOptions={[limit]}
                    // disableColumnMenu

                    // initialState={{
                    //     columns: {
                    //         columnVisibilityModel: {
                    //             Info_id: false,
                    //         },
                    //     },
                    // }}
                    disableCellSelectionOnClick
                    disableSelectionOnClick
                    pagination
                    // onRowClick={(e) => HandleRowClick(e.row)}
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>


        </React.Fragment>
    );
};



export default MobileManagement;
